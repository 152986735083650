<template>
  <div class="card card-custom mb-0 pb-0 ">
    <div class="d-flex py-2 justify-content-center p-4 text-xl">
      <h4>{{ $t('collection.add_collection') }} </h4>
    </div>
    <div class="card-body py-0">
      <div class="row justify-content-center px-4">
        <div class="col-5 text-center">
          <img id="collection-image-holder" class="img-fluid mb-3" :src="cover_image_link" alt="" />

          <input ref="file" style="display: none" accept="image/*" type="file" @change="onChangeFile" />
          <button class="btn btn-sm btn-outline-primary" type="button" @click="$refs.file.click()">
            {{ $t("collectionDetail.add_image") }}
          </button>

        </div>
        <div class="col-7">
          <input type="text" class="uk-input" :placeholder="$t('education.please_add_collection_name')"
            v-model="collectionName">

          <textarea v-model="description" class="uk-textarea mb-3" :placeholder="$t('collectionDetail.description')">
            </textarea>

          <multiselect v-model="hashtags" :multiple="true" :openDirection="'bottom'" :options="hashtags_options"
            :taggable="true" deselectGroupLabel="Grubun seçimini kaldırmak için enter'a basın"
            deselectLabel="Kaldırmak için enter'a basın" label="name" :placeholder="$t('general.add_tag')"
            selectGroupLabel="Grup seçmek için enter'a basın" selectLabel="Seçmek için enter'a basın"
            selectedLabel="Seçilmiş" :tag-placeholder="$t('general.add_tag')" track-by="id"
            @change="(val) => { hashtags = val }" @close="isClosedHashtagSelector = true"
            @open="isClosedHashtagSelector = false" @tag="addTag" />

          <div class="d-flex justify-content-start align-items-center mt-3 mb-3">
            <span class="mr-2">{{ $t('collectionDetail.is_public') }}</span>
            <label class="uk-switch mb-0" for="is_public_check">
              <input type="checkbox" v-model="isPublic" id="is_public_check">
              <div class="uk-switch-slider"></div>
            </label>
          </div>

        </div>
      </div>
    </div>

    <div class="card-footer d-flex justify-content-center p-4">
      <button @click="closeModal" class="btn btn-secondary btn-sm mr-3">
        {{ $t('education.new_send_close') }}
      </button>
      <button @click="onSubmit" :disabled="collectionName === null || collectionName === ''"
        class="btn btn-primary btn-sm">
        <i class="uil-check"></i>
        {{ $t('education.new_send') }}
      </button>
    </div>
  </div>
</template>

<script>

import $ from "jquery";
import Multiselect from "vue-multiselect";
import { CREATE_ITEM } from "@/core/services/store/REST.module";

export default {
  name: "AddCollectionModal",
  components: {
    Multiselect
  },
  props: {},
  data() {
    return {
      collectionName: null,
      description: null,
      collectionUrl: "api/collection",
      cover_image_link: process.env.VUE_APP_BACKEND_APP_URL + "/no-photo.png",
      isPublic: 0,
      hashtags: [],
      isClosedHashtagSelector: false,
      hashtags_options: [],
    }
  },
  methods: {
    addTag(newTag, id = null) {
      var regexp = /^[a-zA-Z0-9-_]+$/;
      if (newTag.search(regexp) == -1) {
        return false;
      }
      const tag = {
        name: newTag,
        id: id ?? 'custom_' + newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.hashtags_options.push(tag);
      this.hashtags.push(tag);
    },
    onChangeFile() {
      let value = this.$refs.file.files[0];
      this.collectionImage = value;
      let blob = URL.createObjectURL(value);
      let imageHolder = $("#collection-image-holder");
      imageHolder.attr("src", blob);
    },
    onSubmit(payload) {
      if (!this.CollectionVisible) {
        return false;
      }

      let self = this;
      let value = this.$refs.file.files[0];

      let form = new FormData();

      form.append("name", this.collectionName);
      form.append("description", this.description);
      form.append("is_public", this.isPublic === true ? 1 : 0);
      form.append("cover_image", value);

      this.hashtags.forEach((item) => {
        form.append("tags", item.name);
      })

      this.$store.dispatch(CREATE_ITEM, {
        url: this.collectionUrl,
        contents: form,
        acceptPromise: true
      })
        .then((result) => {
          if (!self.restError) {
            self.$emit("onSubmit", {
              success: true,
              data: result.data,
            });
          } else {
            self.$emit("onSubmit", {
              success: false,
              message: self.restError
            });
          }
        });
    },
    closeModal() {
      this.$modal.hide('open_to_add_collection_name');
    }
  }
}
</script>

<style scoped>
.button {
  border-radius: 20px;
}
</style>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.uk-sticky-fixed {
  margin: 0 !important;
}

.multiselect .multiselect__input {
  border: unset !important;
  border: 0 !important;
  box-shadow: none;
  outline: none;
}

.vm--modal {
  display: inline-block;
  position: absolute !important;
  max-width: 80% !important;
  max-height: 80% !important;
  margin: auto !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  width: 600px !important;
  height: auto !important;
}
</style>
