<template>
  <li :class="[
    is_locked && !is_completed && !is_unlocked && (!is_demo || has_been_assigned) && can_open_contents_in_order ? 'locked' : '',
    (is_unlocked || is_demo) && !has_been_assigned ? 'unlocked' : '',
    is_completed ? 'completed' : '',
    is_current ? 'current' : '',
    is_unlocked ? 'active' : '',
  ]">
    <div class="icon-cat d-flex align-items-center"
      :style="is_locked && !is_demo && can_open_contents_in_order ? 'cursor:no-drop' : 'cursor:pointer'"
      @click.prevent="linkClick">
      <i :class="icon" class="mr-1"></i>{{ name }}
    </div>
    <a href="javascript:void(0)" :style="is_locked && !is_demo && can_open_contents_in_order ? 'cursor:no-drop' : ''"
      @click.prevent="linkClick">
      {{ title }}<span style="font-weight: bold;" v-if="contentItem?.pivot.timed_content_type === 'timed_content'">
        (Başlama Tarihi: {{
          contentItem.pivot.starts_at }} Bitiş Tarihi: {{ contentItem.pivot.ends_at }})</span></a>
    <span>
      <span class="uil-lock-alt"
        v-if="is_locked && !is_completed && !is_unlocked && !is_demo && can_open_contents_in_order"></span>
      <template v-if="is_completed"><i v-if="certificate_id" @click="$emit('show_certificate')"
          :uk-tooltip="'title:Tamamlama Sertifikası'" class="uil-award mr-1 cursor-pointer"></i><i
          class="uil-check-circle mr-1"></i></template>
      <template v-if="has_been_assigned">{{ point | nullToZero }} {{ $t("point.point") }}</template>
      <span v-if="!has_been_assigned && is_demo">Demo</span>
    </span>
  </li>
</template>

<script>
import Vue from 'vue';
Vue.filter('nullToZero', function (value) {
  if (!value) return 0

  return value;
});
export default {
  name: "ContentItem",
  props: {
    title: {
      required: true,
    },
    point: {
      required: true,
    },
    name: {
      required: true,
    },
    icon: {
      required: true,
    },
    is_completed: {
      required: false,
      default: false,
    },
    has_been_assigned: {
      required: false,
      default: true,
    },
    is_current: {
      required: false,
      default: false,
    },
    is_locked: {
      required: false,
      default: true,
    },
    is_unlocked: {
      required: false,
      default: false,
    },
    is_demo: {
      required: false,
      default: false,
    },
    certificate_id: {
      required: false,
      default: null,
    },
    can_open_contents_in_order: {
      required: false,
      default: null,
    },
    contentItem: {
      required: false,
      default: null,
    }
  },
  methods: {
    linkClick() {
      if (this.is_locked && !this.is_completed && !this.is_unlocked && !this.is_demo && this.can_open_contents_in_order)
        return false;
      this.$emit("linkClick");
    },
  },
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}

.course-curriculum-list .active {
  background: #d6d7e8;
}
</style>

