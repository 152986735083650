import { render, staticRenderFns } from "./AddCollectionModal.vue?vue&type=template&id=4f96ca77&scoped=true&"
import script from "./AddCollectionModal.vue?vue&type=script&lang=js&"
export * from "./AddCollectionModal.vue?vue&type=script&lang=js&"
import style0 from "./AddCollectionModal.vue?vue&type=style&index=0&id=4f96ca77&prod&scoped=true&lang=css&"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&prod&lang=css&"
import style2 from "./AddCollectionModal.vue?vue&type=style&index=2&id=4f96ca77&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f96ca77",
  null
  
)

export default component.exports